import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";

import AI_Film from "@/assets/images/icons/trusted-partners/AI-film.webp";
import nftFest from "@/assets/images/icons/trusted-partners/NFTFEST.webp";
import token2049 from "@/assets/images/icons/trusted-partners/Token-2049.webp";
import mecoConference from "@/assets/images/icons/trusted-partners/img-featurein-Meco-Conference2.webp";
// import NFC from "@/assets/images/icons/trusted-partners/img-featurein-NFC.svg";
import nftNyc from "@/assets/images/icons/trusted-partners/img-featurein-NFT-nyc.webp";
import eurasiaPhoto from "@/assets/images/icons/trusted-partners/img-featurein-association.webp";
import awardPhoto from "@/assets/images/icons/trusted-partners/img-featurein-awards.webp";
import blockchainEconomy from "@/assets/images/icons/trusted-partners/img-featurein-blockchain-economy2.webp";
import blockchainExpo from "@/assets/images/icons/trusted-partners/img-featurein-blockchain-expo.webp";
import emergeConf from "@/assets/images/icons/trusted-partners/img-featurein-emerge.webp";
import teamZ from "@/assets/images/icons/trusted-partners/img-featurein-teamZ.webp";
import tmrwConf from "@/assets/images/icons/trusted-partners/img-featurein-tmrw-conf.webp";
import web3Wave from "@/assets/images/icons/trusted-partners/img-featurein-web3.webp";
import parisBlockChain from "@/assets/images/icons/trusted-partners/img-paris-blockchain.webp";
import Innoblock from "@/assets/images/icons/trusted-partners/innoblock.webp";
import wow_summit from "@/assets/images/icons/trusted-partners/wow-summit.png";

import Image from "next/image";

function TrustedPartnersSection({ homeStyle }) {
  return (
    <>
      <div className={`center featurein ${homeStyle["featurein"]}`}>
        <div className="featurein__header">
          <div
            className={`hot__title h3 burst center ${homeStyle["home__featureIn_center"]} `}
          >
            <BurstPuckerImageTitles />
            Event Collaborators<span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://aifilm3.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={144}
                  height={32}
                  loading="lazy"
                  src={AI_Film}
                  alt="TMRW Conf"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The fun and rewarding photo <br /> challenge app
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://tmrwconf.net"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={124}
                  height={32}
                  loading="lazy"
                  src={tmrwConf}
                  alt="TMRW Conf"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The fun and rewarding photo <br /> challenge app
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://blockchaineconomy.istanbul/en"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={200}
                  height={60}
                  loading="lazy"
                  src={blockchainEconomy}
                  alt="Blockchain Economy"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The World&apos;s Most Global Blockchain & <br /> Crypto
              Conference&quot; awaits you.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.nft.nyc/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={105}
                  height={32}
                  loading="lazy"
                  src={nftNyc}
                  alt="NFT NYC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The leading nft event.&quot; Redefining digital <br /> asset
              ownership.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://intech.am/emerge-conf-2024-emerge-returns-to-yerevan/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={165}
                  height={50}
                  loading="lazy"
                  src={emergeConf}
                  alt="Emerge Conf"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The top tech conference in the new <br /> east.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://web3wave.io/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={30}
                  loading="lazy"
                  src={web3Wave}
                  alt="Web3 Wave"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Exploring the decentralized web&apos;s <br /> transformative
              potentials.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://en.eurasia-photo.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={120}
                  height={32}
                  loading="lazy"
                  src={eurasiaPhoto}
                  alt="Eurasia Photo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Creating a bedrock for talented <br /> minds worldwide.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://blockchain-expo.com/global/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={118}
                  height={32}
                  loading="lazy"
                  src={blockchainExpo}
                  alt="Blockchain Expo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Connecting the Blockchain & Web3 <br /> Ecosystem.&quot; Worldwide
              engagement
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://mecoconference.me/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={237}
                  height={32}
                  loading="lazy"
                  src={mecoConference}
                  alt="Meco Conference"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Mediterranean Embedded <br /> Computing Resources&quot;
            </div>
          </div>
          {/* Paris BlockChain Week */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.parisblockchainweek.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={44}
                  quality={100}
                  loading="lazy"
                  src={parisBlockChain}
                  alt="Paris BlockChain Week"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Europe&apos;s biggest blockchain & <br /> digital assets event
            </div>
          </div>
          {/* teamZ */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://bsc.news/post/a-retrospective-of-teamz-web-3-ai-summit-2024"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={40}
                  loading="lazy"
                  src={teamZ}
                  alt="teamZ"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Pioneers and experts in WEB3 <br /> and AI from Japan and abroad
            </div>
          </div>
          {/* wow summit */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://hongkong2024.wowsummit.net/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={130}
                  height={40}
                  loading="lazy"
                  src={wow_summit}
                  alt="wow-summit"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              New concept and fresh ideas, <br /> building upon the triumph of
              previous
            </div>
          </div>
          {/* Awards Photo */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://awards.photos/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={42}
                  loading="lazy"
                  src={awardPhoto}
                  alt="Awards Photos"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A premier photography contest,
              <br /> recognises and rewards excellence.
            </div>
          </div>
          {/* token 2049 */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.asia.token2049.com/partners"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={160}
                  height={42}
                  loading="lazy"
                  src={token2049}
                  alt="token 2049"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The World&apos;`s Largest Web3 and <br /> crypto Event
            </div>
          </div>
          {/* nft fest */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.nftfest.wtf/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={100}
                  height={42}
                  loading="lazy"
                  src={nftFest}
                  alt="NFTFEST"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              NFTFEST is a community-driven <br /> Web3 IRL event.
            </div>
          </div>
          {/* Inno Block */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.abga.asia/portal/innoblock/index/id/97.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={42}
                  loading="lazy"
                  src={Innoblock}
                  alt="Innoblock"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Innoblock is a community-owned, <br /> Builder-central innovation
              festival
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrustedPartnersSection;
